.VLeft{
    height: 17rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.CImg{
    width: 28rem; height: 19rem;
}
.Vmobile{
    display: none;
}
.Vpc{
    display: flex;
    flex-direction: column;
    height: 20rem;
    justify-content: space-evenly;
}
@media(max-width:500px){
    .CImg{
        width: 20rem; height: 15rem;
    }
    .VLeft{
        align-items: center;
        padding: 0 1rem 0 1rem;
    }
    .Vmobile{
        display: flex;
        flex-direction: column;
        height: 15rem;
        justify-content: space-evenly;
    }
    .Vpc{
        display: none;
    }
}