.GLeft{
    height: 19rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.GImg{
    width: 30rem;
}
@media(max-width: 500px){
    .GLeft{
        align-items: center;
        padding: 0 1rem 0 1rem;
    }
    .GImg{
        width: 20rem;
    }
}