.HomeLeft{
    color: #FFFFFF;
    height: 15rem;
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
}
.HImg{
    /* width: 35rem; */
}
.Hbtn1{
    height: 1.8rem; width: 8rem;
    background: #FFFFFF;
    border: 1.5px solid #FFFFFF;
    cursor: pointer;
    font-weight: 700;
    color: #00151E;
}
.Hbtn12{
    height: 1.8rem; width: 8rem;
    background: #FFFFFF;
    border: 1.5px solid #FFFFFF;
    cursor: pointer;
    font-weight: 700;
    color: #00151E;
	margin-left: 1rem;
}




.Hbtn2{
    height: 1.8rem; width: 8rem;
    background: #F3BA2F;
    border: 1.5px solid #F3BA2F;
    cursor: pointer;
    font-weight: 700;
    margin-left: 1rem; 
    color: #00151E;
}
@media(max-width:500px){
    .Hero{
        flex-direction: column;
        align-items: center;
        padding-bottom: 3rem;
    }
    .HImg{
        width: 100%;
        /* padding-left: 2rem; */
    }
    .HomeLeft{
        /* width: 90%; */
        /* padding-left: 2rem; */
        height: 16rem;
    }
	.Hbtn12{
		margin-top:15px;
		margin-left:0px;
	}
}