.HLeft{
    height: 15rem;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}
.HMobile{
    display: none;
}
.Hpc{
    height: 10rem;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}
.HImage{
    width: 30rem;
}
@media(max-width: 500px) {
    .HLeft{
        align-items: center;
        padding: 0 1rem 0 1rem;
        height: 14rem;
    }
    .Hpc{
        display: none;
    }
    .HMobile{
        height: 9rem;
    display: flex;
    justify-content: space-between;
    flex-direction: column;   
    }
    .HImage{
        width: 20rem;
    }
}