.Fhero{
    /* height: 100vh; */
    display: flex;
    flex-direction: column;
  /*  justify-content: space-evenly; */
    align-items: center;
}
.LeftF{
    color: #FFFFFF;
    text-align: center;
    margin-top: 10rem;
}
.RightF{
    height: 5rem; width: 40rem;
   /* background: chartreuse; */
    display: flex; flex-direction: column;
    justify-content: space-between;
    margin-top: 4rem;
}
.Fbtn{
    background: #E5E5E5;
    border: 1.5px solid #E5E5E5;
    font-weight: 700;
    height: 2rem; width: 6rem;
    color: #00151E;
}
.btnU{
    width: 34rem;
    display: flex;
    justify-content: space-between;
}
.btnD{
    width: 40rem;
    display: flex;
    justify-content: space-between;
    padding-left: 5rem;
}
.Fpara{
    padding: 1rem 1rem 0 1rem;
}
@media(max-width: 500px){
    .btnU{
        flex-direction: column;
        width: auto;
        height: 15rem;
    }
    .btnD{
        flex-direction: column;
        width: auto;
        height: 15rem;   
        padding-left: 0rem; 
    }
    .RightF{
        flex-direction: row;
        justify-content: space-around;
        height: auto;
        width: 100%;
    }
    .Fpara{
        font-size: 0.9rem;
        padding: 1rem 1rem 0 1rem;
    }
}