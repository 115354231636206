.About{
    width: 100%; 
    /* height: 100vh; */
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
     /* text-align: center;  */
     padding: 6rem 0 6rem 0;
}
.Ahead{
    display: flex;
    justify-content: center;
}
.pc{
    height: 20rem;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}
.mobile{
    display: none;
}
@media(max-width:500px){
    .About{
        height: 100vh;
        padding: 6rem 1rem 6rem 1rem;
        justify-content: space-evenly;
    }
    .mobile{
        height: 35rem;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
    }
    .pc{
        display: none;
    }
}