.Feature{
    display: flex;
    /* height: 100vh;  */
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    padding: 4rem 0 4rem 0;
}
.ImageLine{
    width: 60%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
@media(max-width: 500px){
    .ImageLine{
        flex-direction: column;
        height: 30%;
    }
}