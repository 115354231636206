.CRight{
    height: 14rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.CMobile{
    display: none;
}
.Gimage{
    width: 28rem;
}
@media(max-width:500px) {
    .CRight{
        align-items: center;
        padding: 0 1rem 0 1rem;
    }
    .Hero{
        flex-direction: column-reverse;
    }
    .Cpc{
        display: none;
    }
    .CMobile{
        height: 9rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .Gimage{
        width: 20rem;
    }
}