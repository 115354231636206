*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Chakra Petch', sans-serif;
  background: #00151E; 
  color: #FFFFFF;
}
.Hero{
  display: flex;
  /* height: 100vh; */
   width: 100%;
  /* justify-content: space-evenly; */
  align-items: center; 
}