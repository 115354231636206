 /* Navigation Bar */
         .nav{
            background: #00151E;
            border: 2px solid rgba(243, 186, 47, 0.15);
            display: flex;
            justify-content: space-evenly;
            align-items:center;
            height: 4rem; width: 100%;
            padding-left: 3rem !important;
            box-shadow: rgba(243, 186, 47, 0.15);
            position: fixed;
            z-index: 900;

        }
        .Logo{
            display: flex;
            align-items: center;
            width: 9rem;
            justify-content: space-between;
        }
        .list{
            color: #FFFFFF;
            list-style: none;
            display: flex;
            justify-content: space-between;
           /* background: aqua; */
            width: 400px;
            text-decoration: none;
        }
        .Hbtna{
           /* background: chartreuse; */
            width: 220px;
            display: flex;
            justify-content: space-between;
        }
        .Nbtn1{
            color: #FFFFFF;
            background: #00151E;
            border: 1.5px solid #F3BA2F;
            height: 30px; width: 100px;
            cursor: pointer;
        }
        .Nbtn2{
            height: 30px; width: 100px;
            background: #F3BA2F;
            border: 1.5px solid #F3BA2F;
            cursor: pointer;
            color: #00151E;
            font-weight: 700;
        }
        .NLi{
            text-decoration: none;
            color: #FFFFFF;
        }
        .none{
            width: 42rem;
            display: flex;
            justify-content: space-between;
        }
       
        @media(max-width: 768px){
            .nav{
                justify-content: space-between;
                padding-left: 2rem;
                height: 3.5rem;
                z-index: 900;

            }
            .none{
                display: none;
            }
            .hamburger{
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;
                height: 1.8rem;
                padding-right: 2rem;
                cursor: pointer;
            }
            .Line1,.Line2,.Line3{
                height: 0.25rem;
                width: 2.3rem;
                border: 1px solid #FFFFFF;
                background: #FFFFFF;
                border-radius: 0.5rem;
                transition: 1.5s;
            }
            .list{
                flex-direction: column;
                 /* align-items:flex-end;  */
                 height: 15rem;
            }
            .container1{
                display: block;
                right: 0;
                left: 5rem;
                top: 3.5rem;
                transition: all 0.5s ease-out ;
                flex-direction: column;
                position: absolute;
                width: 100%;
                height: 21rem;
                padding-top: 2rem;
                padding-left: 1rem;
            }
            .Hbtna{
                right:50%;
                padding-top: 1rem;
            }
        }
                               /* Navigartion Bar Ends */