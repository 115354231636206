.Faq{
    color: #FFFFFF;
    padding-top: 5rem;
    /* min-height: 100vh; */
}
.Fbox button{
	margin-right: 1rem;
float: right;
margin-top: 5%;
}
.links{
	background:transparent;
	color:#F3BA2F;
}
.ans{
	background: rgba(255,255,255,0.1);
margin-bottom: 0;
padding: 11px;
	
}

.FboxContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 2rem;
}
.Fbox{
    border: 1px solid #FFFFFF;
    justify-content: space-between;
    align-items: center;
    min-height: 2.7rem; width: 25rem;
    font-size: 0.9rem;
    margin-top: 0.5rem;
    cursor: pointer;
}
.FLast{
    display: flex;
    justify-content: center;
    text-align: center;
    padding-top: 1rem;
    position: relative;
}
.Footer{
    height: auto;
}
.icon{
    /* background: red; */
    width: 20rem;
    height: 4rem;
    display: flex;
    flex-direction: column;
    margin-left: 8rem;
    padding-top: 2rem ;
    background: transparent;
}
.iconC{
    display: flex;
    justify-content: space-between;
    height: 2rem;
    width: 15rem;
    background: transparent;
}
.bc{
    background:url(./background.png);
    background-size: 100% 100%;
    height: 8rem;
    width: 100%;
}
.smallImage{
    width: 2rem;
    background: transparent;
}
.Fapara{
    padding-top: 1rem;
}

@media(max-width: 500px){
    .Fbox{
        width: 90%;
    }
    .bc{
        height: 6rem;
    }
    .icon{
        margin: 0 0 0 1rem;
        padding: 1rem 0 0 0;
    }
    .Fapara{
        padding-top: 1rem;
        font-size: 0.9rem;
    }
}