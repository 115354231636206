.Road{
    padding-top: 10rem;
    color: #FFFFFF;
}
.Rcontainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1.5rem;
}
.Ritems{
    display: flex;
    width: 16rem;
    justify-content: space-between;
}
.RLine{
    border: 1px solid #8F8F8F;
    background: #8F8F8F;
    width: 65rem;
    height: 0px;
}
.RightHeroR{
    display: flex;
    flex-direction: column;
    align-items: center; 
    padding: 3rem;
}
.RU, .RM,.RD{
    width: 37rem;
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
}
.Rbtn{
    background: #2C3221;
    color: #FFFFFF;
    height: 2rem; width: 6.5rem;
    border: 1.5px solid #2C3221;
    border-radius: 8px;
}
.Rbbtn{
    background: #2C3221;
    color: #FFFFFF;
    height: 2rem; width: 10.5rem;
    border: 1.5px solid #2C3221;
    border-radius: 8px;
}
@media(max-width: 500px){
    .RLine{
        width: 80%;
    }
    .RU, .RM,.RD{
        flex-direction: column;
        height: 100%;
    }
    .RU{
        padding-left: 1rem;
    }
    .RD{
        padding-right: 1rem;
    }
    .Rbtn{
        width:5rem;
    }
    .Rbbtn{
        width: 8rem;
    }
    .RightHeroR{
        align-items: center;
        padding: 0rem;
        flex-direction: row;
        height: 15rem;
        justify-content: space-between;
        margin-top: 1rem;
    }
}