.MLeft{
    height: 22rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.MImg{
    width: 35rem; height: 22rem;
}
.MMobile{
    display: none;
}
.Mpc{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 17rem;
}
@media(max-width:500px){
    .MLeft{
        align-items: center;
        padding: 0 1rem 0 1rem;
        height: 23rem;
    }
    .MImg{
        width: 22rem; height: 15rem;
    }
    .MMobile{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 17rem;
    }
    .Mpc{
        display: none;
    }
}